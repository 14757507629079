@import "../../node_modules/bootstrap5/dist/css/bootstrap.min.css";

@import "partials/_config.scss";
@import "partials/_general.scss";
@import "partials/_nav.scss";
@import "partials/_home.scss";
@import "partials/_page.scss";
@import "partials/_agenda.scss";
@import "partials/_posts.scss";
@import "partials/_post-details.scss";
@import "partials/_budget.scss";
@import "partials/_contact.scss";
@import "partials/_legaltexts.scss";
@import "partials/_error-404.scss";
@import "partials/_footer.scss";
@import "partials/_animations.scss";


