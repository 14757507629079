.legaltext{
    padding: 3rem 0;
    background-color: $white-color;

    .container .row .col{
        h1{
            font-family: 'Poppins-black',sans-serif;
            font-size: 6.5rem;
            color: $primary-color;
            text-align: center;
            margin-bottom: 5rem;
        }

        p{
            color: $black-color;
            text-align: justify;

            a{
                color: $primary-color;
            }
        }

        li{
            list-style: disc;
        }
    }
}
