*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    box-sizing: border-box;
}

$primary-color: #003696;
$primary-color-dark:darken($primary-color,10%);
$secondary-color: #4C6EC7;
$white-color: #ffffff;
$light-color: rgba($white-color, 0.7);
$black-color: #000000;
$dark-color: rgba($black-color, 0.7);
$fondo-color: #f6f6f6;
$breadcrumb-divider: quote("|") !important;

