@font-face {
    font-family:"Poppins";
    src: url("/assets/fonts/poppins-Regular.ttf");
    font-weight:400;
    font-style: normal;
}

@font-face {
    font-family:"Poppins-bold";
    src: url("/assets/fonts/poppins-Bold.ttf");
    font-weight:700;
    font-style: bold;
}

@font-face {
    font-family:"Poppins-black";
    src: url("/assets/fonts/poppins-Black.ttf");
    font-weight:900;
    font-style: bold;
}

@font-face {
    font-family:"Poppins-light";
    src: url("/assets/fonts/poppins-Light.ttf");
    font-weight:300;
}

html{
    font-size: 10px;
}

body{
    font-family: 'Poppins', sans-serif;
    background-color: $white-color;
    color: $primary-color;
    overflow-x: hidden;
}

img{
    display: block;
    max-width: 100%;
    height: unset !important;
}

p,a,li{
    font-size: 1.8rem;
}

a{
    color: $primary-color;
    transition: all 0.3s ease-in-out;

    &:hover{
        color: $secondary-color;
    }
}

.text-normal{
    font-size: 1.8rem;
}

.text-small{
    font-size: 1.4rem;
}
main{
    min-height: 99vh;
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;

    &.open{
        transform: translateX(250px);
    }
}

.header-spacer{
    height: 110px;
}

@media screen and (max-width: 600px) {
    .header-spacer{
        height: 9rem;
    }
}

.text-primary{
    color: $primary-color !important;
}

.text-secondary{
    color: $secondary-color !important;
}

.color-pdf{
    color: #B31100;
}

.button-primary{
    background-color: $primary-color;
    border-color: $primary-color;
    transition: all 0.3s ease-in-out;

    &:hover{
        background-color: $secondary-color;
        border-color: $secondary-color;
    }
}

.li-padre:last-child{
    border-bottom: 1px solid $black-color;
}

.page-item.active .page-link {
    background-color: $primary-color;
    border-color: $primary-color;
}

.page-item:not(.active) .page-link:hover, .page-item:not(.active) .page-link:focus{
    color: $primary-color;
}

a{
    text-decoration: none;
}

.carousel-control-prev, .carousel-control-next{
    visibility: hidden;
}

.carousel-control-prev-icon,.carousel-control-next-icon{
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: rgba($black-color,0.2);
    background-image: none;
    position: relative;

    i{
        position: absolute;
        top: 36%;
        left: 44%;
        font-size: 1.8rem;
    }
}

.video-responsive{
    margin: auto;
    text-align: center;
    margin-top: 3rem;
    overflow: hidden;
    padding-bottom: 720px;
    position: relative;
    height: 0;
    max-width: 1300px;

    iframe{
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    }
}

@media screen and (max-width:1200px){
    .video-responsive{
        padding-bottom: 575px;
    }
}

@media screen and (max-width:992px){
    .video-responsive{
        padding-bottom: 420px;
    }
}

@media screen and (max-width:768px){
    .video-responsive{
        padding-bottom: 320px;
    }
}

.h-map-home{
    height: 600px;
}

.pages ul li, .post-details ul li{
    list-style: disc;
}

//estilos popup mapa

.leaflet-popup-content{
    p{
        font-size: 1.2rem;
        margin: 0 !important;
    }
}

//estilos para hacer zoom y que no desborde
.zoom{
    &:hover img{
        transform: scale(1.1);
    }

    .zoom-img{
        overflow: hidden;
        width: 100%;
        a{
            img{
                transition: transform 0.3s ease-in-out;
                overflow: hidden;
                width: 100%;
            }
        }
    }
}

//sombreado
.sombreado{
    box-shadow: 6px 8px 8px 0 rgba(0, 0, 0, 0.3);
}

//estilos para el formulario

.alert-success,.alert-danger,.alert-warning,.alert-info,.font-size{
    font-size: 1.8rem;
}

.btn-close{
    float: right;
}

//estilo para cambiar color del check del input radio
.form-check-input:checked[type=radio] {
    background-image: none;
    background-color: $primary-color;
    border-color: $primary-color;
}

//relacion de aspecto vertical
.ratio-4x5{
    --bs-aspect-ratio: calc(4 / 5 * 100%);
}

//estilos recaptcha
.captcha{
    span{
        img{
            display: unset;
        }
    }
}
