.articles-home{
    padding: 3rem 0;
    background-color: $white-color;

    .container{
        .indice-pages{
            --bs-breadcrumb-divider: '|';
            margin-bottom: 5rem;
            margin-left: 5rem;

            ol{
                li{
                    font-family: 'Poppins-bold',sans-serif;
                    &.active{
                        color: $primary-color;
                    }
                    a{
                        color: $black-color;
                        transition: all 0.3s ease-in-out;

                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
            }
        }
        .row div{
            .card{
                border: none;
                background: transparent;

                .card-img-top{
                    min-height: 43.5rem;
                    max-height: 51rem;
                    object-fit: cover;
                    box-shadow: rgba($black-color, 0.2) 10px 10px 10px;
                    transition: transform 0.3s ease-in-out;

                    &:hover{
                        transform: scale(1.05);
                    }
                }

                .card-body{
                    display: flex;
                    flex-direction: column;
                    margin-top: 1rem;

                    h2{
                        font-size: 2rem;
                        color: $primary-color;
                        text-decoration: none;
                        display: inline-block;
                        margin-bottom: 1rem;
                    }

                    p{
                        color: $black-color;
                    }
                }
            }
        }
    }
    } .row .col{
        h1{
            font-size: 6.5rem;
            text-align: center;
            font-family: 'Poppins-black',sans-serif;
            color: #2c2822;
            margin-bottom: 5rem;
        }
}
