.animate {
    animation-play-state: paused;
    animation-fill-mode: backwards;
    animation-duration: 1.5s;

    &.in-viewport {
        animation-play-state: running;
    }

    &.fadeIn {
        animation-name: fade-in;
    }

    &.sharpen {
        animation-name: sharpen;
    }

    &.fadeInSharpen {
        animation-name: fade-in-sharpen;
    }

    &.fast {
        animation-duration: .25s;
    }

    &.medium {
        animation-duration: 1.5s;
    }

    &.slow {
        animation-duration: 3s;
    }

    &.delay-05s {
        animation-delay: 1s;
    }

    &.delay-1s {
        animation-delay: 1s;
    }

    &.delay-2s {
        animation-delay: 2s;
    }
}

@for $i from 1 through 20 {
    .oneByOne > *:nth-child(#{$i}) {
        animation-delay: #{.5 * ($i - 1)}s;
    }
}

@for $i from 1 through 50 {
    .oneByOneFast > *:nth-child(#{$i}) {
        animation-delay: #{.125 * ($i - 1)}s;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes sharpen {
    0% {
        filter: blur(5px);
    }

    100% {
        filter: blur(0);
    }
}

@keyframes fade-in-sharpen {
    0% {
        opacity: 0;
        filter: blur(5px);
    }

    100% {
        opacity: 1;
        filter: blur(0);
    }
}

.product-small {
    animation-fill-mode: backwards;
    animation-duration: 1.5s;
    animation-name: fade-in;
}

.product-catalog-grid {
    @for $i from 1 through 50 {
        div:nth-child(#{$i}) .product-small {
            animation-delay: #{.125 * ($i - 1)}s;
        }
    }
}
