.agenda{
    padding: 3rem 0;
    background: $white-color;
    .container{
        .indice-pages{
            --bs-breadcrumb-divider: '|';
            margin-bottom: 5rem;
            margin-left: 5rem;

            ol{
                li{
                    font-family: 'Poppins-bold',sans-serif;
                    &.active{
                        color: $primary-color;
                    }
                    a{
                        color: $black-color;
                        transition: all 0.3s ease-in-out;

                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
            }
        }

        .titulo-pages{
            font-family: 'Poppins-black',sans-serif;
            font-size: 6.4rem;
            color: $primary-color;
            margin-bottom: 8rem;
            margin-left: 5rem;
        }
    }

    .events{
        background: $white-color;

        .container{
            max-width: 95% !important;
            h2{
                font-family: 'Poppins-black',sans-serif;
                font-size: 6.4rem;
                color: $white-color;
                text-align: center;
                margin-bottom: 3rem;
            }

            p{
                color: $white-color;
                text-align: center;
                margin-bottom: 5rem;
            }

            div{
                div{
                    div{
                        //min-height: 24.9rem;
                        //margin-bottom: 3rem;

                        .event-date-home{
                            background: $black-color;
                            color: $white-color;
                            /*display: flex;
                            justify-content: center;
                            align-items: center;*/
                            padding: 4rem 2rem; //nueva

                            p:first-child{
                                font-size: 5rem;
                                font-family: 'Poppins-black',sans-serif;
                                margin-bottom: 0;
                            }
                            p:last-child{
                                font-size: 3rem;
                                font-family: 'Poppins-black',sans-serif;
                                margin-bottom: 0;
                            }
                        }

                        .event-home{
                            background: $white-color;
                            padding: 2rem;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;


                            .event-home-title{
                                font-size: 2.4rem;
                                margin-bottom: 1rem;
                                font-family: 'Poppins-black',sans-serif;
                            }

                            p{
                                color: $primary-color;
                                text-align: left;
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

// ================ Media queries ===================
@media screen and (max-width: 1000px){
    .events{
        .container{
            h2{
                font-size: 4rem;
            }

            div{
                div{
                    div{
                        .event-date-home{
                            p{
                                font-size: 2rem;
                            }
                        }

                        .event-home{
                            .event-home-title{
                                font-size: 1.8rem;
                            }

                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px){
    .agenda{
        .container{
            .indice-pages{
                margin-left: 0;
            }
            .titulo-pages{
                font-size: 4.5rem;
                margin-left: 0;
            }
        }
    }
}
