.post-details{
    background-color: $white-color;
    padding: 3rem 0;

    .container{

        .indice-pages{
            --bs-breadcrumb-divider: '|';
            margin-bottom: 5rem;
            margin-left: 5rem;

            ol{
                li{
                    font-family: 'Poppins-bold',sans-serif;
                    &.active{
                        color: $primary-color;
                    }
                    a{
                        color: $black-color;
                        transition: all 0.3s ease-in-out;

                        &:hover{
                            color: $secondary-color;
                        }
                    }
                }
            }
        }

        .titulo-pages{
            font-family: 'Poppins-black',sans-serif;
            font-size: 6.5rem;
            color: $primary-color;
            text-align: center;
            margin-bottom: 5rem;
        }

        div{
            p{
                color: $black-color;

                img{
                    display: unset;
                    width: unset;
                    margin: 1rem;
                }
            }
        }

        .galeria{
            div{
                &:hover img{
                    transform: scale(1.1);
                }

                .zoom-gallery{
                    overflow: hidden;
                    width: 100%;
                    a{
                        img{
                            min-height: 35rem;
                            max-height: 35rem;
                            object-fit: cover;
                            transition: transform 0.3s ease-in-out;
                            overflow: hidden;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.files-pages{
    padding: 3rem 0;
    background-color: $primary-color;

    .container{
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        a{
            padding: 1rem;
            background-color: #2c2822;
            color: $white-color;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover{
                color: $secondary-color;
            }
        }
    }

}

// ================ Media queries ===================
@media screen and (max-width:768px){
    .post-details{
        .container{
            .titulo-pages{
                font-size: 4.5rem;
            }

            div{
                p{
                    img{
                        display: block;
                        width: 100%;
                    }
                }

                div{
                    width: 100%;
                }
            }
        }
    }
}
