.error-404{
    padding: 3rem 0;
    background-color: $fondo-color;

    .container{
        div{
            h1{
                font-family: 'Poppins-black';
                font-size: 5rem;
                color: $primary-color;
            }

            p{
                color: $black-color;
            }

            div{
                .btn-error{
                    padding: 1rem 2rem;
                    font-size: 1.8rem;
                    background-color: $primary-color;
                    color: $white-color;
                    transition: all 0.3s ease-in-out;

                    &:hover{
                        background-color: $primary-color-dark;
                    }
                }
            }
        }
    }
}
