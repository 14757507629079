.budget{
    background-color: $fondo-color;
    padding: 3rem 0;
    .container{
        .div_form{
            h1{
                font-size: 3.5rem;
                font-family: 'Poppins-bold';
                color: $primary-color;
            }

            form{
                div{
                    .form-label{
                        font-size: 1.8rem;
                    }

                    .form-control{
                        font-size: 1.8rem;
                        border-radius: 30px;
                        padding: .75rem 2rem;
                        border: none;
                        background-color: $white-color;

                        &[type=file]{
                            padding: 0.375rem 0.75rem;
                        }

                        &:focus{
                            border-color: rgba($primary-color,50%);
                            box-shadow: 0 0 0 0.25rem rgba($primary-color,50%);
                        }
                    }

                    textarea{
                        font-size: 1.8rem;
                        border-radius: 20px;
                        padding: .75rem 2rem;

                        &:focus{
                            border-color: rgba($primary-color,50%);
                            box-shadow: 0 0 0 0.25rem rgba($primary-color,50%);
                        }
                    }
                }

                .form-switch{
                    a{
                        color: $secondary-color;
                    }

                    .form-check-label,.form-check-input{
                        font-size: 1.8rem;
                    }
                }

                .btn-custom{
                    padding: 1rem 4rem;
                    font-size: 1.8rem;
                    margin-right: 1rem;
                }
            }
        }
    }
}
