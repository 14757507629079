.home-principal{
    margin-top: 11rem;
    background-color: $fondo-color;
    .slider-container{
        position: relative;
        transition: all 0.5s ease-in-out;

        &:hover button{
            visibility: visible;
        }

        .background-home{

            .imagen-portada{
                vertical-align: middle;
                border-style: none;
                height: calc( 100vh - 11rem) !important;
                object-fit: cover;
                object-position: top;
            }

            .imagen-portada-responsive{
                vertical-align: middle;
                border-style: none;
                height: 100vh !important;
                object-fit: cover;
                display: none;
            }
        }

        .content{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 50%;
            transform: translate(50%);
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: center;

            div{
                width: 100%;
                display: flex;
                justify-content: center;

                img{
                    width: 30%;
                    /*max-width: 464px;
                    max-height: 342px;*/
                }
            }

            h1{
                font-size: 8rem;
                font-family: 'Poppins-bold',sans-serif;
                color: $white-color;
                text-align: right;
                width: 45%;
                text-shadow: 0.5rem 0.5rem $primary-color;
            }

            p{
                font-family: 'Poppins-bold',sans-serif;
                font-size: 3rem;
                color: $white-color;
                text-shadow: 0.5rem 0.5rem $primary-color;
            }

            a{
                padding: 1rem 2rem;
                background-color: $primary-color;
                color: $white-color;
                text-decoration: none;
                transition: all 0.3s ease-in-out;

                &:hover{
                    background-color: $secondary-color;
                }
            }
        }
    }

    .quien-somos-home{
        background-color: $fondo-color;
        width: 100%;
        padding: 3rem 0 10rem 0;

        .container{
            padding: 3rem 0;
            position: relative;

            .fachada-home{
                width: 50%;
                margin-left: auto;
            }

            .text-fachada{
                position: absolute;
                top: 18%;
                z-index: 5;

                h2{
                    font-family: 'Poppins-black',sans-serif;
                    font-size: 6.5rem;
                    width: 60%;
                    text-transform: uppercase;
                    color: $primary-color;
                    padding-left: 6rem;

                    .text-big{
                        font-size: 10.8rem;
                    }
                }

                p{
                    margin: 2rem 73rem 0 6rem;
                    color: $black-color;
                }
            }

            .btn-quienes-somos-home{
                display: flex;
                justify-content: center;
                margin-top: 10rem;

                a{
                    padding: 1rem 4rem;
                    background-color: #2c2822;
                    color: $white-color;
                    text-decoration: none;
                    transition: all 0.3s ease-in-out;

                    &:hover{
                        background-color: rgba(#2c2822,0.7);
                        color: rgba($white-color,0.9);
                    }
                }
            }
        }
    }

    .features-home{
        padding: 3rem 0 23rem 0;
        background-color: $primary-color;

        .container .row .col{
            h2{
                font-size: 6.5rem;
                text-align: center;
                font-family: 'Poppins-black',sans-serif;
                color: $white-color;
                margin-bottom: 5rem;
            }

            p{
                text-align: center;
                margin-bottom: 5rem;
            }

            .row div{
                .card{
                    border: none;
                    background: transparent;

                    .card-img-top{
                        min-height: 43.5rem;
                        max-height: 51rem;
                        object-fit: cover;
                    }

                    .feature-link{
                        transition: all 0.3s ease-in-out;

                        &:hover h3{
                            color: $white-color;
                        }
                    }

                    .card-body{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        h3{
                            font-size: 3rem;
                            font-family: 'Poppins-bold',sans-serif;
                            color: $white-color;
                            text-decoration: none;
                            display: inline-block;
                            margin-bottom: 1rem;
                            margin-top: 2rem;
                            text-align: center;
                        }

                        p{
                            color: $black-color;
                        }
                    }
                }
            }
        }
    }
    .section-video-home{
        background-color: $fondo-color;
    }
    .video-home{
        position: relative;
        top: -10rem;
    }
}


//*******************media queries**********************
@media screen and (max-width:1400px){
    .home-principal{
        .quien-somos-home{
            .container{
                .text-fachada{
                    top: 12%;
                    h2{
                        font-size: 5.5rem;

                        .text-big{
                            font-size: 8.8rem;
                        }
                    }

                    p{
                        margin: 2rem 58rem 0 6rem;
                    }
                }

            }
        }
    }
}

@media screen and (max-width:1200px){
    .home-principal{

        .slider-container{
            .content{
                h1{
                    width: 50%;
                }
            }
        }

        .quien-somos-home{
            .container{
                .text-fachada{
                    top: 4%;

                    h2{
                        font-size: 4.5rem;
                        padding-left: 0rem;

                        .text-big{
                            font-size: 7.8rem;
                        }
                    }

                    p{
                        margin: 2rem 49rem 0 0rem;
                    }
                }

            }
        }
    }
}

@media screen and (max-width:1000px){
    .home-principal{
        .slider-container{
            .content{
                margin-top: 0px;
                gap: 0.3rem;

                h1{
                    font-size: 8rem;
                    width: 90%;
                }
            }
        }

        .quien-somos-home{
            .container{
                .fachada-home{
                    margin: 1rem 1rem 0 1rem;
                    width: unset;
                    transform: translateY(0) !important;
                }

                .text-fachada{
                    position: relative;

                    h2{
                        font-size: 4rem;
                        width: 100%;
                        text-align: center;

                        .text-big{
                            font-size: 4.5rem;
                        }
                    }

                    p{
                        margin: 1rem;

                    }
                }

                .btn-quienes-somos-home{
                    margin-top: 5rem;
                }
            }
        }

        .features-home {
            .container .row .col {
                h2 {
                    font-size: 4rem;
                }
            }
        }
    }
}

@media screen and (max-width:768px){
    .home-principal{
        .slider-container{
            &:hover button{
                visibility: hidden;
            }

            .background-home{
                .imagen-portada-responsive{
                    display: block;
                }
            }

            .content{

                div{
                    img{
                        display: none;
                    }
                }
            }
        }
    }
    .event-date-home{

        p:first-child{
            font-size: 3rem !important;
        }
        p:last-child{
            font-size: 2rem !important;
        }
    }
}


@media screen and (max-width:600px){
    .home-principal{
        margin-top: 9rem;
        .slider-container{
            .background-home{
                .imagen-portada{
                    height: unset !important;
                }
            }
            .content{
                align-items: center;

                a{
                    padding: 0.75rem 1.5rem;
                    font-size: 1.4rem;
                }

                h1{
                    font-size: 3rem;
                    text-align: center;
                }

                h3{
                    width: 100%;
                }
            }
        }

        .features-home{
            padding: 4rem 0 18rem 0;
        }
    }
}
