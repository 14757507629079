header{
    display: block;

    .site-header{
        display: flex;
        flex-flow: row nowrap;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1050;
        align-items: center;
        background: $primary-color;
        justify-content: space-between;

        &.window-scroll{
            background-color: $white-color;
        }

        .logo{
            flex: 0 0 20%;
            display: inline-flex;
            align-items: center;
            gap: 2rem;
            padding: 3rem;

            /*img.window-scroll{
                content: url(/assets/ico/logo-menu.jpg);
            }*/

            button{
                background-color: transparent;
                color: $secondary-color;
                display: none;
                align-items: center;

                i{
                    font-size: 3rem;

                    &.open-menu-icon{
                        opacity: 1;
                        transition: all 0.5s ease-in-out;
                    }

                    &.open-menu-icon.open{
                        transform: translateX(-250px) rotate(180deg);
                    }
                }

                .close-menu-icon{
                    transition: all 0.5s ease-in-out;
                    transform: translateX(-250px);

                    &.open{
                        display: inline;
                        transform: translateX(0px);
                        opacity: 1;
                    }
                }
            }
        }

        nav{
            flex: 0 0 60%;
            display: flex;
            justify-content: center;
            ul{
                li{
                    font-family: 'Poppins-bold', sans-serif;
                    .submenus{
                        background-color: $primary-color;
                        position: absolute;
                        display: none;
                        padding-left: 0;
                        max-width: 300px;

                        li{
                            padding: 1rem 2rem;
                            transition: transform 0.3s ease-in-out;
                            list-style: none;

                            &:not(:last-child){
                                border-bottom: 1px solid rgba($white-color,0.1);
                            }

                            &:hover{
                                transform: translateX(5px);
                            }

                            a{
                                color: $secondary-color;
                                transition: all 0.3s ease-in-out;

                                &:hover{
                                    color: $white-color;
                                }
                            }
                        }
                    }

                    &:hover .submenus{
                        display: block;
                    }

                    a{
                        font-size: 1.7rem;
                        color: $secondary-color;
                        transition: all 0.3s ease;
                        cursor: pointer;

                        &:hover{
                            color: $white-color;
                        }

                        &.window-scroll{
                            color: $primary-color;

                            &:hover{
                                color: $black-color;
                            }
                        }
                    }
                }

                .menu-socials{
                    display: none;
                }
            }

            .btn-pedidos{
                display: none;
            }
        }

        .header-socials{
            flex: 0 0 20%;
            text-align: end;
            padding: 3rem 1rem 3rem 0rem;
            a{
                width: 3rem;
                height: 3rem;
                text-decoration: none;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: $secondary-color;
                transition: all 0.3s ease-in-out;

                i{
                    transition: all 0.3s ease-in-out;
                }

                &:not(:last-child){
                    margin-right: 0.5rem;
                }

                &:first-child{
                    width: unset;
                    height: unset;
                    background: $secondary-color;
                    border-radius: 20px;
                    padding: 0.75rem 1.65rem;
                    color: $white-color;

                    &:hover{
                        background: darken($secondary-color,5%);
                    }
                }

                &:hover{
                    i{
                        color: $white-color;
                    }
                }
            }
        }
    }
}

//************************media queries********************
@media screen and (max-width:1200px) {
    header{
        .site-header{
            transition: transform 0.5s ease-in-out;
            outline: 1px solid transparent;

            &.open{
                transform: translateX(0px);
            }

            .logo{
                flex: 0 0 100%;
                justify-content: space-between;

                button{
                    display: inline-flex;
                    align-items: center;

                    i{
                        font-size: 3rem;

                        &.open-menu-icon{
                            opacity: 1;
                            transition: all 0.5s ease-in-out;
                        }

                        &.open-menu-icon.open{
                            transform: translateX(-250px), rotate(180deg);
                        }
                    }

                    .close-menu-icon{
                        transition: all 0.5s ease-in-out;
                        transform: translateX(-250px);

                        &.open{
                            display: inline;
                            transform: translateX(230px) rotateX(180deg);
                            opacity: 1;
                        }
                    }
                }

                img{
                    transition: transform 0.5s ease-in-out;

                    &.open{
                        transform: translateX(250px);
                    }
                }
            }

            nav{
                ul{
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-wrap: inherit;
                    flex-direction: column;
                    width: 250px;
                    padding-top: 5rem;
                    transform: translate(-100%);
                    transition: transform 0.5s ease-in-out;
                    height: 100rem;
                    background-color: $primary-color;

                    &.open{
                        transform: translateX(0%);
                    }

                    .btn-pedidos{
                        padding: 2rem;
                        background-color: $secondary-color;
                        margin: 0 4rem;
                        display: flex;
                        justify-content: center;
                        border-radius: 50px;
                        color: $white-color;

                        &:hover{
                            background: darken($secondary-color,5%);
                        }
                    }

                    li{
                        // padding-left: 2rem;
                        border-top: 1px solid rgba($black-color,0.04);

                        .submenus{
                            display: block;
                            position: static;
                            height: unset;
                            background-color: unset;
                            padding-top: 0;
                            transform: none;
                            width: unset;
                            padding-left: 0;

                            li{
                                padding-left: 2rem;
                                padding-top: 0;
                                padding-right: 0;
                                padding-bottom: 0;

                                &:hover{
                                    transform: translateX(0);
                                }
                            }
                        }

                        a{
                            &.nav-link{
                                color: $secondary-color;
                                padding-left: 2rem;
                            }

                            &:hover{
                                color: $white-color;
                            }

                            i{
                                display: none;
                            }
                        }
                    }

                    .menu-socials{
                        display: inline-block;
                        padding: 3rem;
                        a{
                            width: 3rem;
                            height: 3rem;
                            text-decoration: none;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            color: $secondary-color;
                            transition: all 0.3s ease-in-out;

                            i{
                                transition: all 0.3s ease-in-out;
                            }
                            &:not(:last-child){
                                margin-right: 0.5rem;
                                margin-bottom: 1rem;
                            }

                            &:hover{
                                i{
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }

            .header-socials{
                display: none;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    header{
        .site-header{
            height: 9rem;

            .logo{
                a{
                    display: flex;
                    justify-content: flex-end;

                    img{
                        width: 75%;
                    }
                }
            }
        }
    }
}

