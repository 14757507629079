footer{
    background-color: $fondo-color;

    .container{
        margin-top: 6rem;

        .img-footer{
            margin-bottom: 6rem;
        }

        /*img{
            margin-bottom: 2rem;

            &:first-child{
                margin: auto;
                width: 420px;
                height: 113px;
            }

            &:nth-child(2){
                margin: auto;
                width: 211px;
                height: 92px;
            }

            &:last-child{
                margin-bottom: 6rem;
                margin-left: auto;
                margin-right: auto;
                width: 512px;
                height: 74px;
            }
        }*/

        p{
            text-align: center;
            color: $secondary-color;
            font-family: 'Poppins-black',sans-serif;
            font-size: 2rem;
            span a{
                transition: all 0.3s ease-in-out;
                color: $white-color;
                &:hover{
                    color: #3a4d96;
                }
            }
        }

        .footer-address{
            margin-bottom: 7rem;
        }

        .logos-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 5rem;

            img{
                display: unset;
            }
        }

        .legal-texts{
            margin-top: 7rem;
        }

        ul{
            .list-inline-item{
                &:first-child{
                    margin-right: 5rem;
                }

                &:not(:first-child,:last-child){
                    margin-right: 2rem;
                }
                p{
                    color: $black-color;
                    font-family: 'Poppins',sans-serif;
                }

                a{
                    text-decoration: none;
                    color: $black-color;
                    transition: all 0.3s ease-in-out;

                    &:hover{
                        color: $secondary-color;
                    }
                }
            }
        }
    }
}


//***************************media queries*****************************
@media screen and (max-width:992px){
    footer{
        .container{
            .logos-footer{
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width:768px) {
    footer{
        .container{
            ul{
                .list-inline-item{
                    &:not(:last-child){
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
}
