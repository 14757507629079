.pages{
    background-color: $white-color;
    padding: 3rem 0;

    .container{
        //para formulario de distribución de gasóleo
        .div_form{
            h1{
                font-size: 3.5rem;
                font-family: 'Poppins-bold';
                color: $primary-color;
            }

            p{
                color: $black-color;
            }

            form{
                div{
                    .form-label{
                        font-size: 1.8rem;
                    }

                    .form-control{
                        font-size: 1.8rem;
                        border-radius: 30px;
                        padding: .75rem 2rem;
                        border: none;
                        background-color: $fondo-color;

                        &:focus{
                            border-color: rgba($primary-color,50%);
                            box-shadow: 0 0 0 0.25rem rgba($primary-color,50%);
                        }
                    }

                    textarea{
                        font-size: 1.8rem;
                        border-radius: 20px;
                        padding: .75rem 2rem;

                        &:focus{
                            border-color: rgba($primary-color,50%);
                            box-shadow: 0 0 0 0.25rem rgba($primary-color,50%);
                        }
                    }
                }

                .form-switch{
                    a{
                        color: $primary-color;
                    }
                    .form-check-label,.form-check-input{
                        font-size: 1.8rem;
                    }
                }

                .btn-custom{
                    padding: 1rem 2rem;
                    font-size: 1.8rem;
                    margin-right: 1rem;
                }
            }
        }
        //fin para formulario de distribución de gasóleo

        .indice-pages{
            --bs-breadcrumb-divider: '|';
            margin-bottom: 5rem;
            margin-left: 5rem;

            ol{
                li{
                    font-family: 'Poppins-bold',sans-serif;
                    &.active{
                        color: $primary-color;
                    }
                    a{
                        color: $black-color;
                        transition: all 0.3s ease-in-out;

                        &:hover{
                            color: $secondary-color;
                        }
                    }
                }
            }
        }

        .titulo-pages{
            font-family: 'Poppins-black',sans-serif;
            font-size: 6.4rem;
            color: $primary-color;
            margin-bottom: 5rem;
            margin-left: 5rem;
        }

        .subtitulo-pages{
            font-size: 3rem;
            color: $primary-color;
            text-align: center;
            margin-bottom: 5rem;
        }

        p{
            color: $black-color;
            text-align: justify;
        }

        ul li{
            color: $black-color;
        }

        img{
            display: unset;
            width: unset;
        }

        .galeria{
            div{
                &:hover img{
                    transform: scale(1.1);
                }

                .zoom-gallery{
                    overflow: hidden;
                    width: 100%;
                    a{
                        img{
                            min-height: 35rem;
                            max-height: 35rem;
                            object-fit: cover;
                            transition: transform 0.3s ease-in-out;
                            overflow: hidden;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    //Normativa
    .normativas{
        h2{
            font-size: 3rem;
            margin-bottom: 1rem;
            &:not(:first-child){
                margin-top: 3rem;
            }
        }
        ul{
            li{
                list-style: none;
                a{
                    color: $black-color;
                    transition: all 0.3s ease-in-out;

                    &:hover{
                        color: $secondary-color;
                    }
                }
            }
        }
    }
}

.files-pages{
    padding: 3rem 0;
    background-color: $white-color !important;

    .container{
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        a{
            padding: 1rem;
            background-color: $primary-color !important;
            color: $white-color;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover{
                color: $secondary-color;
            }
        }
    }

}

// Equipo
.equipo-title{
    font-size: 4rem;
    color: $primary-color;
    font-family: 'Poppins-black',sans-serif;
}

.equipo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;

    img {
        max-width: unset !important;
        height: 100% !important;
        width: 100% !important;
        display: block !important;
        object-fit: contain;
    }
}

.textos-equipo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;

    h2{
        font-size: 3rem;
        color: $primary-color;
        margin-bottom: 1rem;
        text-align: center;
    }

    p{
        text-align: center !important;
    }
}

// ================ Media queries ===================
@media screen and (max-width:768px){
    .pages{
        .container{
            .indice-pages{
                margin-left: 0;
            }
            .titulo-pages{
                font-size: 4.5rem;
                margin-left: 0;
            }

            .subtitulo-pages{
                font-size: 2rem;
            }

            img{
                display: block !important;
                width: 100% !important;
            }
        }
    }
}
